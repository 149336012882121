import React , {useState} from 'react';
import './filter.css'

var launchyearfilter,  launchsuccess,landsuccess="";
function Filter(props) {

    const [isSelected, setisSelected] = useState(false);
    
    // const [launchyearfilter, setlaunchyearfilter] = useState();
    // const [launchsuccess, setlaunchsuccess] = useState();
    // const [landsuccess, setlandsuccess] = useState();


    const handleChange = event => {

        const name = event.target.name;
        const value = event.target.innerText.toString();
        if (name == "launch_year") {
            launchyearfilter=value;
            let span_year= document.getElementsByName("launch_year");
            span_year.forEach((el) => {
                el.classList.remove("active-div");
            })
            event.target.classList.add("active-div");
        } else if (name == "launch_span") {
            launchsuccess=value;
            
            let span_launch= document.getElementsByName("launch_span");
            span_launch.forEach((el) => {
                el.classList.remove("active-div");
            })
            event.target.classList.add("active-div");

            console.log(window,"windows");
        } else if (name == "land_span") {
            landsuccess=value;
            let span_land= document.getElementsByName("land_span");
            span_land.forEach((el) => {
                el.classList.remove("active-div");
            })
            event.target.classList.add("active-div");

        }
      
        // setisSelected(true);
        event.target.classList.add('active-div');
        props.onClick(event.target,launchyearfilter,launchsuccess,landsuccess);
    }

    return (
        <div className='filter_box'>
            <div className="d2_box">
                <h2 className="h2_text">Filters</h2>
                <label className="filter_text">Launch Year</label>
                <div className="year_select">
                    <div className="d2">
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text" >2006</button>
                            {/* <span className="year_text">2006</span> */}
                            <br />
                        </div>
                        <br />
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text" >2008</button>
                            {/* <span className="year_text">2008</span><br /> */}
                        </div>
                        <br />
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text" >2010</button>
                            {/* <span className="year_text">2010</span><br /> */}
                        </div>
                        <br />
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text" >2012</button>
                            {/* <span className="year_text">2012</span><br /> */}
                        </div>
                        <br />
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text" >2014</button>
                            {/* <span className="year_text">2014</span><br /> */}
                        </div>
                        <br />
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text" >2016</button>
                            {/* <span className="year_text">2016</span><br /> */}
                        </div>
                        <br />
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text" >2018</button>
                            {/* <span className="year_text">2018</span><br /> */}
                        </div>
                        <br />
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text">2020</button>
                            {/* <span className="year_text">2020</span><br /> */}
                        </div>

                    </div>
                    <div className="d2">
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text_2">2007</button>
                            {/* <span className="year_text_2">2007</span><br /> */}
                        </div>
                        <br />
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text_2" >2009</button>
                            {/* <span className="year_text_2">2009</span><br /> */}
                        </div>
                        <br />
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text_2" >2011</button>
                            {/* <span className="year_text_2">2011</span><br /> */}
                        </div>
                        <br />
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text_2" >2013</button>
                            {/* <span className="year_text_2">2013</span><br /> */}
                        </div>
                        <br />
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text_2" >2015</button>
                            {/* <span className="year_text_2">2015</span><br /> */}
                        </div>
                        <br />
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text_2" >2017</button>
                            {/* <span className="year_text_2">2017</span><br /> */}
                        </div>
                        <br />
                        <div className="div_filter_year" onClick={handleChange}>
                        <button name="launch_year" className="year_text_2" >2019</button>
                            {/* <span className="year_text_2">2019</span><br /> */}
                        </div>

                    </div>
                </div>
                <div>
                    <label className="filter_text">Successful Launch</label>
                    <div className="year_select" onClick={handleChange}>
                        <div className="d2">
                            <div className="div_filter_year">
                            <button name="launch_span" id="launch_span" className="data_span" >True</button>
                                {/* <span id="launch_span" className="data_span">True</span> */}
                                <br />
                            </div>
                        </div>
                        <div className="d2">
                            <div className="div_filter_year">
                            <button name="launch_span" id="launch_span" className="data_span_2" >False</button>
                                {/* <span id="launch_span" className="data_span_2">False</span> */}
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <label className="filter_text">Successful Landing</label>
                    <div className="year_select" onClick={handleChange}>
                        <div className="d2">
                            <div className="div_filter_year">
                            <button name="land_span" id="land_span" className="data_span" >True</button>
                                {/* <span id="land_span" className="data_span">True</span> */}
                                <br />
                            </div>
                        </div>
                        <div className="d2">
                            <div className="div_filter_year">
                            <button name="land_span" className="year_text_2" >False</button>
                                {/* <span id="land_span" className="year_text_2">False</span> */}
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Filter;