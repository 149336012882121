import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Filter from '../Filter/Filter';
import PropTypes from 'prop-types';

import './home.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router';
import _ from 'lodash';


function Home(props) {


    // const [rocket_name, setrocket_name] = useState(0);
    // const [mission_id, setmission_id] = useState(0);
    // const [Launch_year, setLaunch_year] = useState(0);
    // const [launch_flag, setlaunch_flag] = useState(false);
    // const [landing_laugh, setlanding_laugh] = useState(false);
    const [rocket_data, setrocket_data] = useState([]);
    const [filterq, setfilterq] = useState("");
    const [loading, setloading] = useState(false);


    useEffect(() => {

        const proxyurl = "https://calm-ocean-48165.herokuapp.com/";
        console.log(filterq, "filterq")
        let url = `${'https://api.spaceXdata.com/v3/launches'}`;
        let calltoservice = "";

        if (filterq != "") {
            calltoservice = `${'https://api.spaceXdata.com/v3/launches'}` + filterq;
        } else {
            calltoservice = url;
        }
        setloading(true);
        fetch(proxyurl+calltoservice)
        .then(res => res.json())
        .then(result => {
            setloading(false);
                setrocket_data(result);
        });
    }, [filterq])
    const onClick = (data, p1 , p2, p3) => {

        let filterQuery = "";
        // history.push('/app/');
        // setValue(data)
        if ((p1 == undefined || p1 == "") && (p2 == undefined || p2 == "") && (p3 ==undefined || p3 == "")) {
            filterQuery = "";
        } else if ((p1 == undefined || p1 == "") && !(p2 == undefined || p2 == "") && (p3 ==undefined || p3 == "")) {
            filterQuery = "?launch_success=" + _.toLower(p2);
        } else if (!(p1 == undefined || p1 == "") && (p2 == undefined || p2 == "") && (p3 ==undefined || p3 == "")) {
            filterQuery = "?launch_year=" + p1;
        } else if (!(p3 ==undefined || p3 == "") && (p1 == undefined || p1 == "") && (p2 == undefined || p2 == "")) {
            filterQuery = "?land_success=" +  _.toLower(p3);
        } else if (!(p1 == undefined || p1 == "") && !(p2 == undefined || p2 == "") && (p3 ==undefined || p3 == "")) {
            filterQuery = "?launch_year=" + p1 + "&launch_success=" + _.toLower(p2);
        } else if ((p1 == undefined || p1 == "") && !(p2 == undefined || p2 == "") && !(p3 ==undefined || p3 == "")) {
            filterQuery = "?land_success=" +  _.toLower(p3) + "&launch_success=" + _.toLower(p2);
        } else if (!(p1 == undefined || p1 == "") && (p2 == undefined || p2 == "") && !(p3 ==undefined || p3 == "")) {
            filterQuery = "?launch_year=" + p1 + "&land_success=" +  _.toLower(p3);
        } else if (!(p1 == undefined || p1 == "") && !(p2 == undefined || p2 == "") && !(p3 ==undefined || p3 == "")) {
            filterQuery = "?launch_year=" + p1 + "&launch_success=" + _.toLower(p2) + "&land_success=" +  _.toLower(p3);
        }

        setfilterq(filterQuery);
    }

    return (
        <div className="launch_div">
            <h1 style={{ margin: '1% 0%' }}>
                SpaceX Launch Programs
            </h1>
            <div className="home_layout">
                <div className="e1"><Filter onClick={(e,p1,p2,p3) => { onClick(e,p1,p2,p3) }} /></div>
                <div className="e2">

                    {loading == true ?
                        <>
                            <div>
                                <h3 style={{ textAlign: 'center' }} >Data is being Fetched</h3>
                            </div>

                            <div id="loader_spin" style={{ margin: '0 auto' }}>
                                <div>
                                    <CircularProgress disableShrink style={{ margin: '5px auto', display: 'block' }} />
                                </div>
                                <Typography style={{ textAlign: 'center' }}>Please wait..</Typography>
                            </div>
                        </>
                        :

                        <div className="div_m1">


                            {
                                rocket_data.length > 0 ?
                                    rocket_data.map((item, index) =>
                                        index < 250 ?
                                            <div className="div_m1_l1">
                                                <div className="img_section">
                                                    <img class="rocket_img" src={item.links.mission_patch} alt="Smiley face" />
                                                </div>
                                                <div className="text_rname">
                                                    <span className="rocket_h">{item.mission_name} # {item.flight_number}</span><br />
                                                </div>
                                                <div className="rocket_metadata">
                                                    <div>
                                                        <label className="lbl_rocket">Mission Ids</label>
                                                        <ul>
                                                            {item.mission_id.map((element) =>
                                                                <li>{element}</li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <label className="lbl_rocket">Launch Year:</label>
                                                        <span>{item.launch_year}</span>
                                                    </div>
                                                    <div>
                                                        <label className="lbl_rocket">Successful Launch:</label>
                                                        {item.launch_success == true ?
                                                            <span>True</span>
                                                            :
                                                            <span>False</span>
                                                        }
                                                    </div>
                                                    <div>
                                                        <label className="lbl_rocket">Successful Landing:</label>
                                                        {item.launch_landing == true ?
                                                            <span>True</span>
                                                            :
                                                            <span>False</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    )
                                    :
                                    <Typography style={{ textAlign: 'center' }}>
                                        <h3>No Data Found, Please choose a different Filter..</h3></Typography>

                            }




                        </div>
                    }
                </div>
            </div>

        </div>
    )
}



export default Home;